
import { Component, Vue } from 'vue-property-decorator';

import { get } from 'lodash-es';
import { CompanyId, getItem } from '@WS_UIkit';
import WsHeptaList from '@WS_Components/WsHeptaList.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import { TransferPaymentData } from '@/store/const';
import { bankImages } from '@/components/paymentOptions/bankImages';
import { StorageKeys } from '@/services/const';
import { AppState } from '@/store/const';
import config from '@/services/config.service';

import caryImage from '@/assets/images/Cary/cary-ok.png';

@Component({
  components: {
    WsHeptaList,
    WsMessage,
    WsDelimiter,
  },
})
export class TransferPayMessage extends Vue {
  imgs = bankImages;
  caryImage = caryImage;
  useIBAN = config.company === CompanyId.WEBSUPPORT_SK;

  get qrImg() {
    return get(this.transferData.options, '[0].payBySquareImageUrl', '');
  }

  // solves issue PNO-1566
  // localstorage restoration of transferPaymentData (after refresh)
  get transferData(): TransferPaymentData {
    return (
      (this.$store.state as AppState).transferPaymentData ||
      (getItem(StorageKeys.transferPaymentData, true) as TransferPaymentData) ||
      {}
    );
  }
}
export default TransferPayMessage;
