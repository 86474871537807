
import { Component, Vue } from 'vue-property-decorator';

import { get, snakeCase } from 'lodash-es';
import { scrollToTop } from '@loopia-group/utils';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import { ROUTENAMES } from '@/const.enum';
import { safePush } from '@/router';
import { fetchCart } from '@/services/cart/cart-api.service';
import { TransferPayMessage } from '@/components/thankYou/TransferPayMessage.vue';
import { ResultMessage } from '@/components/thankYou/ResultMessage.vue';
import { config } from '@/services/config.service';
import { PAY_OPTIONS } from '@/components/paymentOptions/const';
import { Theme } from '@loopia-group/services';

@Component({
  components: {
    TransferPayMessage,
    ResultMessage,
    WsMessage,
    WsDelimiter,
  },
})
export default class ThankYou extends Vue {
  supportUrl: string = config.supportUrl || 'www.google.com';
  PAY_OPTIONS = PAY_OPTIONS;
  Theme = Theme;
  companyKey = snakeCase(config.company);

  get messageKey() {
    return get(this.$route, 'query.messageKey', null);
  }

  get transactionId() {
    return get(this.$route, 'query.transactionId', null);
  }

  created() {
    scrollToTop();
    this.checkProcessingCase();
  }

  updated() {
    this.checkProcessingCase();
  }

  checkProcessingCase() {
    // feature: not to show processing page where ther is no checkout process
    // even the fact I cannot distinguish whether checkout is not running on BE, sorry ¯\_(ツ)_/¯
    if (
      !this.$route?.query?.forceStay &&
      this.messageKey === 'processing' &&
      !this.$store.state.checkoutInProgress
    ) {
      fetchCart();
      safePush({ name: ROUTENAMES.CART });
    }
  }
}
