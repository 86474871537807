var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page row justify-center ws-typography",class:{
    dark: _vm.messageKey !== _vm.PAY_OPTIONS.TRANSFER,
    light: _vm.messageKey === _vm.PAY_OPTIONS.TRANSFER,
  }},[_c('div',{staticClass:"col main-wrapper full-width q-px-md",class:{
      'q-pt-5xl': _vm.messageKey !== 'processing',
      'q-pb-5xl': _vm.messageKey !== 'processing' && !_vm.isTheme(_vm.Theme.CARY),
    }},[(_vm.messageKey === _vm.PAY_OPTIONS.TRANSFER)?_c('transfer-pay-message'):_c('result-message',{attrs:{"message-key":_vm.messageKey,"transaction-id":_vm.transactionId}})],1),(!_vm.isTheme(_vm.Theme.LOOPIA))?_c('div',{staticClass:"footer col-12 text-center"},[_c('div',{staticClass:"wrapper col row justify-center items-center q-py-4xl"},[_c('div',{staticClass:"col-12 text-center"},[_c('h6',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('thank_you.footer.title'))+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm.$t('thank_you.footer.text1'))),_c('a',{staticClass:"footer-link q-px-xs",attrs:{"href":_vm.supportUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('thank_you.footer.link')))]),_vm._v(_vm._s(_vm.$t(`thank_you.footer.${_vm.companyKey}.text2`, { _fallback: 'thank_you.footer.text2', _silent: true, }))+" ")])])]),_c('ws-delimiter')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }