
import {defineComponent} from 'vue';

import WsBadge from './WsBadge.vue';

export default defineComponent({
  components: {
    WsBadge,
  },
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
  },
});
