
import { Component, Vue, Prop } from 'vue-property-decorator';

import { redirect } from '@loopia-group/utils';
import WsBack from '@WS_Components/WsBack.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import { config } from '@/services/config.service';
import { Cart } from '@/types';
import thankYouImg from '@/assets/images/Cary/cary-thankyou.png';
import errorImg from '@/assets/images/Cary/cary-sorry.png';
import mailImg from '@/assets/images/Cary/cary-processing.png';
import confirmImg from '@/assets/images/Cary/cary-processing.png';
import okImg from '@/assets/images/Cary/cary-ok.png';
import { processOrder } from '@/services/order.service';
import { Theme } from '@loopia-group/services';
import WsSpinner from '@WS_Components/WsSpinner.vue';

interface ThankYouMessage {
  img?: string;
  loader?: boolean;
  title: string;
  info?: string;
  transactionText?: string;
  infoText1?: string;
  infoLink1?: string;
  infoHref1?: string;
  infoText2?: string;
  back?: { label: string; fn?: () => void };
  btn?: {
    label: string;
    fn: () => void;
  };
  meta?: any; // custom data specific for particular case
}

@Component({
  components: {
    WsSpinner,
    WsBack,
    WsButton,
    WsMessage,
  },
})
export class ResultMessage extends Vue {
  @Prop()
    messageKey!: string | null;
  @Prop()
    transactionId!: string | null;

  get exception(): (string | null)[] | string | null {
    return this.$route.query?.exception || null;
  }

  get invoiceUrl(): string | null {
    const invoiceId =
      this.$route.query?.invoiceId ||
      this.$store.state.invoiceId ||
      null;

    const invoiceUrl = invoiceId
      ? config.baseInvoiceUrl + '/' + invoiceId
      : null;
    return invoiceUrl || config.invoiceUrl /* fallback to deprecated */ || null;
  }

  readonly cart!: Cart;
  Theme = Theme;

  defaultMessage: ThankYouMessage = {
    img: errorImg,
    title: 'thank_you.unknown_message.title',
    info: 'thank_you.unknown_message.info',
    back: { label: 'thank_you.unknown_message.back' },
  };

  // eslint-disable-next-line max-lines-per-function
  get messages(): { [key: string]: ThankYouMessage } {
    const exception = this.exception || '';
    const invoiceUrl = this.invoiceUrl || config.webAdminUrl || '/';
    const tryAdminInvoice = 'exception.try_admin_invoice';
    const orderNotCreated = 'exception.order.not_created';
    const otherHandled = 'exception.other_handled';
    const generalException = 'exception.general';
    let exceptionKey = generalException;
    let exceptionGroup = generalException;

    if (exception) {
      exceptionGroup =
        ({
          // grouping map
          'exception.invoice.timeout': tryAdminInvoice,
          'exception.invoice.retrieval_failed': tryAdminInvoice,
          'exception.invoice.not_found': tryAdminInvoice,
          'exception.payment.option_not_found': tryAdminInvoice,
          'exception.payment.card_payment_failed': tryAdminInvoice,
          [orderNotCreated]: otherHandled,
          // @ts-ignore
        } as Record<string, string>)[exception] || generalException;
      // @ts-ignore
      exceptionKey =
        exceptionGroup === otherHandled ? exception : exceptionGroup;
    }

    const back = {
      label: 'thank_you.back',
      fn: () => {
        redirect(config.webUrl || '/');
      },
    };

    return {
      success: {
        img: thankYouImg,
        title: 'thank_you.success.title',
        infoText1: 'thank_you.success.info',
        transactionText: 'thank_you.success.transaction_text',
        back,
        btn: {
          label: 'thank_you.success.btn_label',
          fn: () => {
            redirect(config.webAdminUrl || '/');
          },
        },
      },

      mail: {
        img: okImg,
        title: 'thank_you.mail.title',
        info: 'thank_you.mail.info',
        back,
      },

      fail: {
        img: errorImg,
        title: 'thank_you.fail.title',
        infoText1: 'thank_you.fail.info_text1',
        infoLink1: 'thank_you.fail.info_link1',
        infoText2: 'thank_you.fail.info_text2',
        transactionText: 'thank_you.fail.transaction_text',
        infoHref1: invoiceUrl,
        btn: {
          label: 'thank_you.fail.btn_label',
          fn: () => redirect(invoiceUrl),
        },
        back,
      },

      exception: {
        img: exceptionGroup === tryAdminInvoice ? mailImg : errorImg,
        title: 'thank_you.exception.title',
        info: `thank_you.${exceptionKey}.info`,
        btn:
          ({
            [orderNotCreated]: {
              label: `thank_you.${orderNotCreated}.btn_label`,
              fn: () => {
                processOrder(true, true);
              },
            },
            [tryAdminInvoice]: {
              label: `thank_you.${tryAdminInvoice}.btn_label`,
              fn: () => redirect(invoiceUrl),
            },
            [generalException]: {
              label: `thank_you.${generalException}.btn_label`,
              fn: () => redirect(invoiceUrl),
            },
          } as Record<string, ThankYouMessage['btn']>)[exceptionKey] ||
          undefined,
        meta: { exceptionGroup },
        back,
      },

      cancel: {
        img: errorImg,
        title: 'thank_you.cancel.title',
        btn: {
          label: 'thank_you.cancel.btn_label',
          fn: () => {
            redirect(invoiceUrl);
          },
        },
        back,
      },

      confirm: {
        img: confirmImg,
        title: 'thank_you.confirm.title',
        info: 'thank_you.confirm.info',
        back,
      },

      processing: {
        loader: true,
        title: 'thank_you.processing.title',
        info: 'thank_you.processing.info',
      },
    };
  }

  get message() {
    return this.messageKey
      ? this.messages[this.messageKey] || this.defaultMessage
      : this.defaultMessage;
  }

  created() {
    if (this.exception) {
      this.$messageService.errorHandler('general')(this.exception);
    }
  }
}
export default ResultMessage;
